// Font loader
import WebFont from "webfontloader";

// Carousels
import jobSearch from "../modules/job-search-carousel";

export default {
  init() {
    // JavaScript to be fired on all pages
    WebFont.load({
      google: {
        families: ["Roboto:400,500,700", "Nunito Sans:400,500,700,800,900"],
      },
    });

    // Menu Toggle
    const buttons = document.querySelectorAll(".js-menu-toggle");

    // Loop through the elements
    for (const button of buttons) {
      button.addEventListener("click", (e) => {
        let menu = document.getElementById("menu-collapse");

        // Toggle the hide class
        menu.classList.toggle("menu-show");

        if (menu.classList.contains("menu-show")) {
          enableBodyScroll(menu);
        } else {
          disableBodyScroll(menu);
        }
      });
    }

    // Mobile Menu Dropdowns
    const mobileMenu = document.querySelector("#menu-collapse");
    const mobileSubLinks = mobileMenu.querySelectorAll(".menu-item-has-children > a");

    for (const mobileSubLink of mobileSubLinks) {
      mobileSubLink.addEventListener("click", (e) => {
        let viewportWidth = window.innerWidth;

        if (viewportWidth < 1024) { // Is Mobile
          e.preventDefault();
          e.target.nextElementSibling.classList.toggle('js-show-mobile');

          // console.log(e.target.nextElementSibling.classList);
        }
      });
    }

    // Job Search Carousel
    if (document.body.contains(document.querySelector(".job-search"))) {
      jobSearch();
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  loaded() {
    // Javascript to be fired on page once fully loaded
  },
};
