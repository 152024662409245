// Import Flickity
import Flickity from 'flickity';

const jobSearch = () => {
  // Previous
  const previousButton = document.querySelector(
    '.job-search .carousel-controls .js-carousel-button-previous'
  );

  // Next
  const nextButton = document.querySelector(
    '.job-search .carousel-controls .js-carousel-button-next'
  );

  const flickity = new Flickity('.job-search-carousel', {
    cellAlign: 'left',
    contain: false,
    prevNextButtons: false,
    pageDots: false,
    on: {
      ready: () => {
        previousButton.classList.add('disabled');
      },
    },
  });

  previousButton.addEventListener('click', function () {
    flickity.previous();
  });

  nextButton.addEventListener('click', function () {
    flickity.next();
  });

  flickity.on('change', () => {
    if (flickity.selectedIndex == 0) {
      previousButton.classList.add('disabled');
    }

    if (flickity.selectedIndex == 1) {
      previousButton.classList.remove('disabled');
    }

    if (flickity.selectedIndex == flickity.cells.length - 1) {
      nextButton.classList.add('disabled');
    } else {
      nextButton.classList.remove('disabled');
    }
  });
};

export default jobSearch;
